import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
 
Vue.use(Vuex)
 
axios.defaults.headers.common['Authorization'] = 'Basic cHM6MkxYS0JLdWtYRnUwSXE0U1RFT0dGYkRnQ3J0aWZBNHIhaEFMY1ohcg==' // for all requests
export default new Vuex.Store({
  state: {
    jobs: [],
    bildungswege: [],
    berufsgruppen: [],
    arbeitsfelder: [],
    countryPrefix: 'de',
    stopList: [
      'typnr',
      'hauptberuf_id',
      'id',
      'lehrberuf',
      'auslaufend',
      'ausgelaufen',
      'lbnr',
      'modul_lbnr',
      'riasec1',
      'riasec2',
      'riasec3',
      'kurzbeschreibung_raw',
      'berufsgruppen_primaer'
    ]
  },
  mutations: {
    setJobs(state, payload) {
      state.jobs = payload
    },
    setCountryPrefix(state, payload) {
      state.countryPrefix = payload
    },
    setBildungswege(state, payload) {
      state.bildungswege = payload
    },
    setBerufsgruppen(state, payload) {
      state.berufsgruppen = payload
    },
    setArbeitsfelder(state, payload) {
      state.arbeitsfelder = payload
    },
  },
  actions: {
    async getJobs({state, commit}) {
      try {
        const response = await axios.get(`https://futurvoc.i-smart.li/api/${state.countryPrefix}/berufe/61810b29-eb57-4b78-ade3-e356450badc3`)
        commit('setJobs', response.data)
      } catch (e) {
        console.log(e)
      }
    },
    async getJobById({state}, id) {
      try {
        const response = await axios.get(`https://futurvoc.i-smart.li/api/${state.countryPrefix}/beruf/61810b29-eb57-4b78-ade3-e356450badc3/${id}`)
        return response.data
      } catch (e) {
        console.log(e)
      }
    },
    async getBildungswege({state, commit}) {
      try {
        const response = await axios.get(`https://futurvoc.i-smart.li/api/${state.countryPrefix}/bildungswege/61810b29-eb57-4b78-ade3-e356450badc3`)
        commit('setBildungswege', response.data)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line
    async getBildungswegeData({state}, id) {
      try {
        const response = await axios.get(`https://futurvoc.i-smart.li/api/${state.countryPrefix}/berufe_bildungsweg/61810b29-eb57-4b78-ade3-e356450badc3/${id}`)
        return response.data
      } catch (e) {
        console.log(e)
      }
    },
    async getBerufsgruppen({state, commit}) {
      try {
        const response = await axios.get(`https://futurvoc.i-smart.li/api/${state.countryPrefix}/berufsgruppen/61810b29-eb57-4b78-ade3-e356450badc3`)
        commit('setBerufsgruppen', response.data)
      } catch (e) {
        console.log(e)
      }
    },
    async getBerufsgruppenData({state}, id) {
      try {
        const response = await axios.get(`https://futurvoc.i-smart.li/api/${state.countryPrefix}/berufsgruppe/61810b29-eb57-4b78-ade3-e356450badc3/${id}`)
        return response.data
      } catch (e) {
        console.log(e)
      }
    },
    async getArbeitsfelder({state, commit}) {
      try {
        const response = await axios.get(`https://futurvoc.i-smart.li/api/${state.countryPrefix}/arbeitsfelder/61810b29-eb57-4b78-ade3-e356450badc3`)
        commit('setArbeitsfelder', response.data)
      } catch (e) {
        console.log(e)
      }
    },
    async getArbeitsfelderData({state}, id) {
      try {
        const response = await axios.get(`https://futurvoc.i-smart.li/api/${state.countryPrefix}/arbeitsfeld/61810b29-eb57-4b78-ade3-e356450badc3/${id}`)
        return response.data
      } catch (e) {
        console.log(e)
      }
    },
  },
  modules: {}
})
