/* eslint-disable */

function lenSort(a, b) {
  return a.length - b.length;
}

var intersec = module.exports = function(arrays) {
  arrays.sort(lenSort);
  
  var arraysDicts = [];
  for (var arrayIndex = 1; arrayIndex < arrays.length; arrayIndex++) {
    var dict = {};
    for (var index = 0; index < arrays[arrayIndex].length; index++) {
      dict[arrays[arrayIndex][index]] = true;
    }
    arraysDicts.push(dict);
  }
  
  var res = [];
  for (var index = 0; index < arrays[0].length; index++) {
    var flag = true;
    for (var arrayIndex = 0; arrayIndex < arraysDicts.length; arrayIndex++) {
      if (!(arrays[0][index] in arraysDicts[arrayIndex])) {
        flag = false;
        break;
      }
    }
    if (flag) res.push(arrays[0][index]);
  }
  
  return res;
};