<template>
  <div class="popup-container" @click.stop="">
    <div v-if="jobData && !isLoading" class="popup-content" color="basil">
      <div class="title">
        <h2>{{jobData.bezeichnung}}</h2>
      </div>
    
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="basil"
        grow
      >
        <v-tab
          v-for="item in tabs"
          :key="item"
        >
          {{ item }}
        </v-tab>
      </v-tabs>
    
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in tabs"
          :key="item"
        >
          <v-card
            color="basil"
            flat
          >
            <v-card-text class="tab-item description" v-html="jobData[item]"></v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div v-if="!jobData && !isLoading" class="popup-content">
      <div class="title">
        <h2>{{content.id}}</h2>
        <v-btn v-if="content.class === 'item'" @click="showGroup" text small>
          Show details
        </v-btn>
      </div>
      <div class="description" v-html="content.beschreibung"/>
    </div>
    <v-progress-circular
      v-if="isLoading"
      :size="50"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>

</template>

<script>
  export default {
    name: "Popup",
    props: ['content'],
    data() {
      return {
        jobData: null,
        tab: null,
        tabs: [],
        isLoading: false
      }
    },
    mounted() {
      if (this.content.apiId) this.getJobData()
    },
    computed: {
      stopList() {
        return this.$store.state.stopList
      }
    },
    watch: {
      content() {
        if (this.content.apiId) this.getJobData()
      }
    },
    methods: {
      getJobData() {
        this.isLoading = true
        this.$store.dispatch('getJobById', this.content.apiId)
          .then(response => {
            this.jobData = response.beruf
            this.tabs = []
            let info = ''
            for (let key in this.jobData) {
              if (this.stopList.includes(key)) continue
              const value = this.jobData[key]
              if (typeof value === 'string' && this.jobData[key][0] === '<') {
                this.tabs.push(key)
              }
              else if (typeof value === 'string' && this.jobData[key][0] && this.jobData[key][0] !== '{') {
                info += `<p><strong>${key}:</strong> ${this.jobData[key]}</p>`
              }
            }
            if (info) {
              this.jobData.info = info
              this.tabs.unshift('info')
            }
      
            this.isLoading = false
          })
      },
      showGroup() {
        this.$emit('showGroup')
      }
    }
  }
</script>

<style lang="scss">
  .popup-container {
    width: 600px;
    min-height: 10vh;
    max-height: 50vh;
    z-index: 6;
    background-color: #fff;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    box-shadow: 0 0.625rem 1.25rem rgba(100, 100, 140, .1);
    border-radius: 0.5rem;
    border: 1px solid #e6e6fa;
    
    .v-progress-circular {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .popup-content {
    width: 100%;
    text-align: justify;
    padding: 1.25rem;
    
    .tab-item {
      max-height: 30vh;
      overflow-y: auto;
    }
    
    .title {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      
      h2 {
        font-size: 16px;
        line-height: 28px;
        font-weight: bold;
      }
      
      button {
        vertical-align: center;
        align-self: center;
      }
    }
    
    .description {
      border-top: 1px solid #e6e6fa;
      font-size: 12px;
      line-height: 16px;
      padding: 12px 0;
      
      p {
        margin-bottom: 6px;
      }
    }
  }
</style>