var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"filters",attrs:{"data-app":""}},[_c('v-row',{staticStyle:{"align-items":"center"}},[_c('v-col',{staticClass:"filters_preview"},[_c('v-row',{staticStyle:{"margin":"10px 0 0 0","flex-wrap":"nowrap"}},[_c('v-text-field',{attrs:{"hide-details":"","label":"Job Suche","clearable":"","outlined":"","dense":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearch($event)},"click:clear":_vm.onClear},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticStyle:{"height":"40px","margin-left":"-5px"},attrs:{"dark":""},on:{"click":_vm.onSearch}},[_vm._v(" SEARCH")])],1)],1),_c('v-col',{staticClass:"filters_select"},[_c('v-select',{ref:"selectCountry",attrs:{"hide-details":"","items":_vm.countries,"label":"Land"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1),_c('v-col',{staticClass:"filters_select"},[_c('v-select',{ref:"selectOne",attrs:{"hide-details":"","disabled":!_vm.bildungswegeOptions.length > 0,"items":_vm.bildungswegeOptions,"item-text":"label","menu-props":{ maxHeight: '400' },"label":"Bildungswege","multiple":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" "+_vm._s(_vm.bildungswegeValues.length === 1 ? _vm.bildungswegeValues.length + ' selection' : _vm.bildungswegeValues.length + ' selections')+" ")]):_vm._e()]}}]),model:{value:(_vm.bildungswegeValues),callback:function ($$v) {_vm.bildungswegeValues=$$v},expression:"bildungswegeValues"}})],1),_c('v-col',{staticClass:"filters_select"},[_c('v-select',{ref:"selectTwo",attrs:{"hide-details":"","disabled":!_vm.berufsgruppenOptions.length > 0,"items":_vm.berufsgruppenOptions,"item-text":"label","menu-props":{ maxHeight: '400' },"label":"Berufsgruppen","multiple":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" "+_vm._s(_vm.berufsgruppenValues.length === 1 ? _vm.berufsgruppenValues.length + ' selection' : _vm.berufsgruppenValues.length + ' selections')+" ")]):_vm._e()]}}]),model:{value:(_vm.berufsgruppenValues),callback:function ($$v) {_vm.berufsgruppenValues=$$v},expression:"berufsgruppenValues"}})],1),_c('v-col',{staticClass:"filters_select"},[_c('v-select',{ref:"selectThree",attrs:{"hide-details":"","disabled":!_vm.arbeitsfelderOptions.length > 0,"items":_vm.arbeitsfelderOptions,"item-text":"label","menu-props":{ maxHeight: '400' },"label":"Arbeitsfelder","multiple":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" "+_vm._s(_vm.arbeitsfelderValues.length === 1 ? _vm.arbeitsfelderValues.length + ' selection' : _vm.arbeitsfelderValues.length + ' selections')+" ")]):_vm._e()]}}]),model:{value:(_vm.arbeitsfelderValues),callback:function ($$v) {_vm.arbeitsfelderValues=$$v},expression:"arbeitsfelderValues"}})],1),_c('v-col',{staticClass:"filters_actions"},[_c('v-row',[_c('v-btn',{staticStyle:{"margin":"10px 10px 0 0"},attrs:{"dark":""},on:{"click":_vm.onFilterToggle}},[_vm._v(" Filter ")]),_c('v-btn',{staticStyle:{"margin-top":"10px"},on:{"click":_vm.onReset}},[_vm._v(" Reset ")])],1)],1)],1)],1),(_vm.filtersParams.length > 0)?_c('div',{staticClass:"selected-filters"},_vm._l((_vm.filtersParams),function(item,i){return _c('div',{key:i,staticClass:"selected-filters__item"},[_c('p',[_c('v-icon',[_vm._v("mdi-tag")]),_vm._v(" "+_vm._s(item.label)+" ")],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.removeGroup(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)}),0):_vm._e(),_c('div',{attrs:{"id":"chart"}}),(_vm.content)?_c('Popup',{staticClass:"popup",attrs:{"content":_vm.content},on:{"showGroup":_vm.showContentGroup}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }