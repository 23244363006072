<template>
  <div>
    <div class="filters" data-app>
      <v-row style="align-items: center">
        <v-col
          class="filters_preview"
        >
          <v-row style="margin: 10px 0 0 0; flex-wrap: nowrap;">
            <v-text-field
              hide-details
              label="Job Suche"
              v-model="search"
              clearable
              @keydown.enter="onSearch"
              @click:clear="onClear"
              outlined
              dense
            ></v-text-field>
            <v-btn @click="onSearch" style="height: 40px; margin-left: -5px;" dark> SEARCH</v-btn>
          </v-row>
        </v-col>
        <v-col
          class="filters_select"
        >
          <v-select
            hide-details
            ref="selectCountry"
            v-model="country"
            :items="countries"
            label="Land"
          ></v-select>
        </v-col>
        <v-col
          class="filters_select"
        >
          <v-select
            hide-details
            ref="selectOne"
            v-model="bildungswegeValues"
            :disabled="!bildungswegeOptions.length > 0"
            :items="bildungswegeOptions"
            item-text="label"
            :menu-props="{ maxHeight: '400' }"
            label="Bildungswege"
            multiple
            return-object
          >
            <template v-slot:selection="{ item, index }">
              <span
                v-if="index === 0"
                class="grey--text caption"
              >
                {{ bildungswegeValues.length === 1 ? bildungswegeValues.length + ' selection' : bildungswegeValues.length + ' selections' }}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
          class="filters_select"
        >
          <v-select
            hide-details
            ref="selectTwo"
            v-model="berufsgruppenValues"
            :disabled="!berufsgruppenOptions.length > 0"
            :items="berufsgruppenOptions"
            item-text="label"
            :menu-props="{ maxHeight: '400' }"
            label="Berufsgruppen"
            multiple
            return-object
          >
            <template v-slot:selection="{ item, index }">
              <span
                v-if="index === 0"
                class="grey--text caption"
              >
                {{ berufsgruppenValues.length === 1 ? berufsgruppenValues.length + ' selection' : berufsgruppenValues.length + ' selections' }}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
          class="filters_select"
        >
          <v-select
            hide-details
            ref="selectThree"
            v-model="arbeitsfelderValues"
            :disabled="!arbeitsfelderOptions.length > 0"
            :items="arbeitsfelderOptions"
            item-text="label"
            :menu-props="{ maxHeight: '400' }"
            label="Arbeitsfelder"
            multiple
            return-object
          >
            <template v-slot:selection="{ item, index }">
              <span
                v-if="index === 0"
                class="grey--text caption"
              >
                {{ arbeitsfelderValues.length === 1 ? arbeitsfelderValues.length + ' selection' : arbeitsfelderValues.length + ' selections' }}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
          class="filters_actions"
        >
          <v-row>
            <v-btn @click="onFilterToggle" dark style="margin: 10px 10px 0 0;">
              Filter
            </v-btn>
            <v-btn @click="onReset" style="margin-top: 10px;">
              Reset
            </v-btn>
          </v-row>
        
        </v-col>
      </v-row>
    </div>
    <div v-if="filtersParams.length > 0" class="selected-filters">
      <div class="selected-filters__item"
         v-for="(item, i) in filtersParams"
         :key="i">
        <p>
          <v-icon>mdi-tag</v-icon>
          {{ item.label }}
        </p>
        <v-btn icon small @click="removeGroup(item)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    <div id="chart"/>
    <Popup class="popup" v-if="content" :content="content" @showGroup="showContentGroup"/>
  </div>
</template>

<script src="../../public/hyphenate.js" type="text/javascript"> </script>
<script>
  import {mapState} from "vuex";
  import * as d3 from "d3"
  import intersec from './utils'
  import Popup from "../components/Popup";
  import $ from "jquery";
  
  export default {
    name: 'Home',
    components: {
      Popup,
    },
    data() {
      return {
        data: [],
        country: 'de',
        countries: [
          {
            text: 'Germany',
            value: 'de'
          },
          {
            text: 'Austria',
            value: 'at'
          },
          {
            text: 'Switzerland',
            value: 'ch'
          }
        ],
        svg: null,
        link: null,
        node: null,
        circles: null,
        labels: null,
        content: '',
        isFiltered: false,
        
        bildungswegeOptions: [],
        bildungswegeValues: [],
        berufsgruppenOptions: [],
        berufsgruppenValues: [],
        arbeitsfelderOptions: [],
        arbeitsfelderValues: [],
        search: '',
        
        primaryColor: '#baa3ff',
        secondaryColor: '#e6e6fa'
      }
    },
    computed: {
      ...mapState({
        jobs: state => state.jobs,
        bildungswege: state => state.bildungswege,
        berufsgruppen: state => state.berufsgruppen,
        arbeitsfelder: state => state.arbeitsfelder,
        countryPrefix: state => state.countryPrefix
      }),
      filtersParams() {
        return [...this.bildungswegeValues, ...this.berufsgruppenValues, ...this.arbeitsfelderValues]
      }
    },
    watch: {
      country(value) {
        this.$store.commit('setCountryPrefix', value)
      }
    },
    async created() {
      await this.setDefaultData()
      this.drawChart()
      
      window.addEventListener("resize", this.dimensionsChanged);
    },
    destroyed() {
      window.removeEventListener("resize", this.dimensionsChanged);
    },
    methods: {
      removeGroup(group) {
        const type = group.type.charAt(0).toLowerCase() + group.type.slice(1)
        this[`${type}Values`] = this[`${type}Values`].filter(item => item !== group)
        
        if (!this.filtersParams.length && this.isFiltered) {
          this.onReset()
        }
        if (this.isFiltered) {
          this.onFilter()
        }
      },
      onFilterToggle() {
        this.isFiltered = true
        this.onFilter()
      },
      async onFilter() {
        const data = {
          nodes: [],
          links: [],
        }
        
        const groupTypes = Array.from(new Set(this.filtersParams.map(item => item.type)))
        if (groupTypes.length === 1) {
          for (let i in this.filtersParams) {
            const response = await this.$store.dispatch(`get${this.filtersParams[i].type}Data`, this.filtersParams[i].value)
            data.nodes.push({id: this.filtersParams[i].label, size: 70, class: 'sub-title'})
            const uniqData = response.berufe.filter((item, index, self) =>
               index === self.findIndex((t) => (
                t.brfid === item.brfid
              ))
            )
            uniqData.forEach(job => {
              data.nodes.push({
                id: job.bezeichnung,
                size: 18,
                class: 'item',
                apiId: job.brfid
              })
              data.links.push({
                source: this.filtersParams[i].label,
                target: job.bezeichnung
              })
            })
          }
        } else if (groupTypes.length > 0 && groupTypes.length <= 3) {
          const results = []
          for (let i in groupTypes) {
            let resultByType = []
            const groupsByType = this.filtersParams.filter(param => param.type === groupTypes[i])
            for (let j in groupsByType) {
              const data = await this.$store.dispatch(`get${groupsByType[j].type}Data`, groupsByType[j].value)
              resultByType = [...resultByType, ...data.berufe.map(d => d.brfid)]
            }
            results.push(resultByType)
          }
          const ids = intersec(results)
          const resultJobs = this.jobs.berufe.filter(d => ids.includes(d.brfid))
          resultJobs.forEach(job => {
            data.nodes.push({
              id: job.bezeichnung,
              size: 18,
              class: 'item',
              apiId: job.brfid
            })
            data.links.push({
              source: `Berufe: ${resultJobs.length}`,
              target: job.bezeichnung
            })
          })
          data.nodes.push({id: `Berufe: ${resultJobs.length}`, size: 70, class: 'sub-title'})
        }
        this.content = ''
        this.search = ''
  
        if (data.nodes.length) {
          this.data = data
        } else {
          await this.setDefaultData()
        }
        
        this.clearChart()
        this.drawChart()
      },
      async onReset() {
        this.bildungswegeValues = []
        this.berufsgruppenValues = []
        this.arbeitsfelderValues = []
        this.search = ''
        this.isFiltered = false
  
        await this.setDefaultData()
        this.clearChart()
        this.drawChart()
      },
      clearChart() {
        document.getElementById('chart').innerHTML = ''
      },
      dimensionsChanged() {
        this.clearChart()
        this.drawChart()
      },
      showContentGroup() {
        this.isFiltered = true
        const type = this.content.type.charAt(0).toLowerCase() + this.content.type.slice(1)
        this[`${type}Values`] = [{
          label: this.content.id,
          type: this.content.type,
          value: this.content.groupId
        }]
        this.onFilter()
      },
      async onClear() {
        this.clearChart()
        await this.setDefaultData()
        this.drawChart()
      },
      onSearch() {
        if (!this.search) return
        
        this.bildungswegeValues = []
        this.berufsgruppenValues = []
        this.arbeitsfelderValues = []
        
        const searchResult = this.jobs.berufe.filter(job => job.bezeichnung.includes(this.search))
        
        const data = {
          nodes: [],
          links: [],
        }
        searchResult.forEach(job => {
          data.nodes.push({
            id: job.bezeichnung,
            size: 18,
            class: 'item',
            apiId: job.brfid
          })
          data.links.push({
            source: `Berufe ${searchResult.length}`,
            target: job.bezeichnung
          })
        })
        data.nodes.push({id: `Berufe ${searchResult.length}`, size: 70, class: 'sub-title'})
        this.data = data
        
        this.clearChart()
        this.drawChart()
      },
      async setDefaultData() {
        await this.$store.dispatch('getBildungswege')
        await this.$store.dispatch('getBerufsgruppen')
        await this.$store.dispatch('getArbeitsfelder')
        this.$store.dispatch('getJobs')
        
        const data = {
          nodes: [
            {id: this.countryPrefix.toUpperCase(), size: 120, class: 'main-title'},
            {id: 'Bildungswege', size: 70, class: 'sub-title'},
            {id: 'Berufsgruppen', size: 70, class: 'sub-title'},
            {id: 'Arbeitsfelder', size: 70, class: 'sub-title'},
          ],
          links: [
            {
              source: this.countryPrefix.toUpperCase(),
              target: 'Bildungswege'
            },
            {
              source: this.countryPrefix.toUpperCase(),
              target: 'Berufsgruppen'
            },
            {
              source: this.countryPrefix.toUpperCase(),
              target: 'Arbeitsfelder'
            },
          ]
        }
        this.bildungswege.bildungswege.forEach(item => {
          data.nodes.push({
            id: item.bezeichnung,
            size: 18,
            class: 'item',
            beschreibung: item.beschreibung,
            type: 'Bildungswege',
            groupId: item.id
          })
          data.links.push({
            source: 'Bildungswege',
            target: item.bezeichnung
          })
          this.bildungswegeOptions.push({
            value: item.id,
            label: item.bezeichnung,
            type: 'Bildungswege'
          })
        })
        this.berufsgruppen.berufsgruppen.forEach(item => {
          data.nodes.push({
            id: item.bezeichnung,
            size: 18,
            class: 'item',
            beschreibung: item.beschreibung,
            type: 'Berufsgruppen',
            groupId: item.id
          })
          data.links.push({
            source: 'Berufsgruppen',
            target: item.bezeichnung
          })
          this.berufsgruppenOptions.push({
            value: item.id,
            label: item.bezeichnung,
            type: 'Berufsgruppen'
          })
        })
        this.arbeitsfelder.arbeitsfelder.forEach(item => {
          data.nodes.push({
            id: item.bezeichnung,
            size: 18,
            class: 'item',
            beschreibung: item.beschreibung,
            type: 'Arbeitsfelder',
            groupId: item.arbfeldid
          })
          data.links.push({
            source: 'Arbeitsfelder',
            target: item.bezeichnung
          })
          this.arbeitsfelderOptions.push({
            value: item.arbfeldid,
            label: item.bezeichnung,
            type: 'Arbeitsfelder'
          })
        })
        
        this.data = data
      },
      blurRefs() {
        this.$refs.selectOne.blur();
        this.$refs.selectTwo.blur();
        this.$refs.selectThree.blur();
      },
      drawChart() {
        const height = document.getElementById('chart').offsetHeight
        const width = document.getElementById('chart').offsetWidth
        
        this.svg = d3.select('#chart').append("svg")
          .attr('height', height)
          .attr('width', width)
          .call(d3.zoom()
            .on("zoom", d => this.svg.attr("transform", d.transform)))
          .attr("viewBox", [0, 0, width, height])
          .append('g')
        
        const links = this.data.links
        const nodes = this.data.nodes
  
        const simulation = d3.forceSimulation(nodes)
          .force("link", d3.forceLink(links).id(d => d.id).strength(0.2))
          .force("charge", d3.forceManyBody())
          .force("center", d3.forceCenter(width / 2, height / 2))
          .force('collision', d3.forceCollide().radius(d => d.size * 1.5))
        
        d3.select('#chart').on("click", () => {
          this.blurRefs()
        });
        
        d3.select('body').on("click", () => {
          this.content = ''
        });
        
        this.link = this.svg.append("g")
          .attr("stroke", this.secondaryColor)
          .attr("stroke-opacity", 0.6)
          .selectAll("line")
          .data(links)
          .join("line")
          .attr("stroke-width", 2);
        
        const click = d => {
          event.stopPropagation();
          const data = d3.select(d.target).data()[0]
          this.blurRefs()
          if (data.class !== 'sub-title' && data.class !== 'main-title') this.content = data
        }
        
        const setHighlight = d => {
          this.svg.style("cursor", "pointer");
          this.circles.attr("stroke", this.secondaryColor)
          const selectedNode = d3.select(d.target)
          this.circles.attr("stroke", o => isConnected(selectedNode.data()[0], o) ? this.primaryColor : this.secondaryColor)
          this.link.style("stroke", o =>
            o.source.index == selectedNode.data()[0].index || o.target.index == selectedNode.data()[0].index
              ? this.primaryColor
              : this.secondaryColor);
        }
        
        const exitHighlight = () => {
          this.circles.attr("stroke", this.primaryColor)
          this.link.style("stroke", this.secondaryColor)
        }
        
        var linkedByIndex = {};
        this.data.links.forEach(function (d) {
          linkedByIndex[d.source.index + "," + d.target.index] = true;
        });
        
        function isConnected(a, b) {
          return linkedByIndex[a.index + "," + b.index] || linkedByIndex[b.index + "," + a.index] || a.index == b.index;
        }
        
        this.node = this.svg.append("g")
          .attr("stroke", "#fff")
          .attr("stroke-width", 1.5)
          .selectAll("g")
          .data(nodes)
          .join("g")
        
        const drag = simulation => {
          function dragstarted(event) {
            if (!event.active) simulation.alphaTarget(0.1).restart();
            event.subject.fx = event.subject.x;
            event.subject.fy = event.subject.y;
          }
          
          function dragged(event) {
            event.subject.fx = event.x;
            event.subject.fy = event.y;
          }
          
          function dragended(event) {
            if (!event.active) simulation.alphaTarget(0);
            event.subject.fx = null;
            event.subject.fy = null;
          }
          
          return d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended);
        }
        
        // set container for text label, which can be styled
        this.labels = this.node.append("foreignObject")
          .attr('x', d => -d.size)
          .attr('y', d => -d.size)
          .attr("width", d => d.size * 2)
          .attr("height", d => d.size * 2)
          .html(d => `<div style="height: ${d.size * 2}" class="${d.class}"><p>${d.id}</p></div>`)
        
        this.circles = this.node.append("circle")
          .attr("r", d => d.size)
          .attr("id", d => d.id)
          .attr("fill", 'rgba(255,255,255, .0)')
          .attr("stroke-width", 2)
          .attr("stroke", this.primaryColor)
          .on("click", click)
          .on("mouseover", setHighlight)
          .on("mouseout", exitHighlight)
          .call(drag(simulation))
        
        simulation.force("link")
          .links(this.data.links);
        
        simulation.on("tick", () => {
          this.link
            .attr("x1", d => d.source.x)
            .attr("y1", d => d.source.y)
            .attr("x2", d => d.target.x)
            .attr("y2", d => d.target.y);
          
          this.labels.attr("transform", function (d) {
            return "translate(" + d.x + "," + d.y + ")";
          })
          
          this.circles
            .attr("cx", d => d.x)
            .attr("cy", d => d.y);
        });
  
        // add classes for hyphenate
        $(document).ready(function() {
          $('h2, h1, h4, h3, h5, p, a').each(function () {
            $(this).addClass('hyphenate');
          });
          Hyphenator.run();
        });
      },
    }
  }
</script>


<style lang="scss">
  .filters {
    padding: 10px 20px;
    width: 100%;
    background-color: #fff;
    
    .v-menu__content {
      top: 64px !important;
    }

    .v-list-item__content {
      flex: none;
      margin-left: 5px;
    }
  
    .v-text-field--outlined fieldset {
      padding-left: 17px;
    }
  
    .v-text-field--outlined.v-input--dense .v-label--active {
       transform: translateY(-20px) scale(0.75);
    }
    
    &_select, &_preview {
      flex-basis: 17% !important;
    }
    
    &_actions {
      flex-basis: 12% !important;
    }
  }
  
  .selected-filters {
    position: absolute;
    top: 75px;
    left: 10px;
    padding: 15px;
    width: 300px;
    text-align: start;
    
    background-color: #fff;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    box-shadow: 0 0.625rem 1.25rem rgba(100, 100, 140, .1);
    border-radius: 0.5rem;
    border: 1px solid #e6e6fa;
    
    &__item {
      display: flex;
      justify-content: space-between;
      
      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 250px;
        line-height: 28px;
      }
    }
  }
  
  #chart {
    width: 100%;
    height: calc(100vh - 72px);
  }
  
  .popup {
    position: absolute;
    right: 10px;
    top: 75px;
  }
  
  .main-title, .sub-title, .item {
    color: #3f426c;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    
    height: 100%;
    border-radius: 100%;
    text-align: center;
    background-color: #fff;
  }
  
  .main-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .sub-title {
    font-size: 14px;
    padding: 15px;
    
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }
  
  .item {
    font-size: 7px;
    line-height: 8px;
    box-sizing: border-box;
    padding: 6px;
    
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      height: 100%;
      display: inline-block;
      vertical-align: middle;
      word-wrap: break-word;
    }
  }
</style>
